<template>
  <v-row no-gutters justify="center" align="center" class="mt-4">
    <v-card width="1000px" height="800">
      <v-container>
        <h2 class="my-5">จัดการคำถาม</h2>
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mt-4"
            color="#833133"
            style="color: white"
            @click="onClickGenerateQR()"
            >สร้าง QR Code ทุกจังหวัด
          </v-btn>
          <v-btn
            class="mr-4 mt-4"
            color="#833133"
            style="color: white"
            @click="createQuestion()"
            ><v-icon left dark> mdi-plus-circle </v-icon>เพิ่ม
          </v-btn>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="list1"
          :search="search"
          :items-per-page="10"
          class="elevation-1"
        >
          <!-- <template v-slot:[`item.idCardImg`]="{ item }">
          <img @click="popup(item)" :src="item.idCardImg" />
        </template> -->

          <template v-slot:[`item.type`]="{ item }">
            <v-row justify="center">
              <span v-if="item.type === 'C'">คะแนน</span>
              <span v-if="item.type === 'T'">กรอก</span>
              <span v-if="item.type === 'DROPDOWN'">Drop Down</span>
              <span v-if="item.type === 'CHECKBOX'">Checkbox</span>
              <span v-if="item.type === 'RADIO'">Radio</span>
            </v-row>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-row justify="center">
              <v-icon @click="DeleteQuestion(item)">mdi-delete</v-icon>

              <v-icon @click="UpdateQuestion(item)">mdi-pencil</v-icon>

              <!-- <v-icon @click="GetAnswers(item)">mdi-magnify</v-icon> -->
            </v-row>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-row>
</template>

<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      search: "",
      //   dialog: false,
      headers: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "คำถาม", value: "topic", align: "center" },
        { text: "ประเภทคำถาม", value: "type", align: "center" },
        // { text: "สร้างเมื่อ", value: "createDate", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      list1: [],
      formId: "",
      count: 0,
    };
  },
  created() {
    this.getFormId();
    this.getQuestion();
  },
  methods: {
    getFormId() {
      const response = JSON.parse(
        Decode.decode(localStorage.getItem("Questions"))
      );
      // this.list1 = response.question;
      this.formId = response.id;
      // console.log("response", response);
      // console.log("list1", this.list1);
    },
    async getQuestion() {
      const id = this.formId;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getForm/` + id
      );
      console.log("response", response.data.data.question);
      this.list1 = response.data.data.question;
      for (let i in this.list1) {
        this.list1[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    async DeleteQuestion(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/form/removeQuestion/` + val.id
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          await location.reload();
        }
      });
    },
    UpdateQuestion(val) {
      console.log("val", val);
      localStorage.setItem("UpdateQuestion", Encode.encode(val));
      this.$router.push("UpdateQuestion");
    },
    createQuestion() {
      this.$router.push("CreateQuestion");
    },
    async onClickGenerateQR() {
      this.$swal({
        text: "คุณต้องการลสร้าง QR Code สำหรับทุกจังหวัด ใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            formId: this.formId,
          };
          console.log("data", data);
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/form/generateQRByForm`,
            data
          );
          console.log(response.data.response_status);
          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              title: `สร้าง QR Code สำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.$router.push("ManageQRcode");
          } else {
            this.$swal.fire({
              icon: "error",
              title: response.data.error,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    },
  },
};
</script>
